import { template as template_ab87b5d6c0fe4c72ae24d3ed1c58e9e9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_ab87b5d6c0fe4c72ae24d3ed1c58e9e9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
