import { template as template_2c0cf6097fcd41cfa382544b3c8a74ed } from "@ember/template-compiler";
const WelcomeHeader = template_2c0cf6097fcd41cfa382544b3c8a74ed(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
