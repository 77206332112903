import { template as template_b42a6a88284440e2a238102797e013d7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_b42a6a88284440e2a238102797e013d7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
