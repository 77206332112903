import { template as template_60753abda41c495b85617ef44ac461f1 } from "@ember/template-compiler";
const FKText = template_60753abda41c495b85617ef44ac461f1(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
